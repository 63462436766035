<template>
  <v-row no-gutters>
    <v-col>
      <div id="product-list">
        <!-- app drawer -->

        <product-add-update
          v-model="isAddUpdateProductSidebarActive"
          :product-type="productType"
          :product-item="productItem"
          @refetch-data="fetchProducts"
          @upload-image="uploadImage"
          @save-product-event="saveProductEvent"
        ></product-add-update>

        <product-filter v-model="isFilterProductSidebarActive" @filter-data="filterData"> </product-filter>

        <Image-upload
          v-model="isUploadProductImageActive"
          :data="dataUpload"
          @refetch-data="fetchProducts"
          @save-image-event="saveImageEvent"
        />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? '#3b355a' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiCollage }}
              </v-icon>
              Ürünler
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn plain text small @click.stop="isFilterProductSidebarActive = !isFilterProductSidebarActive">
              <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
              </v-badge>
              <v-icon v-else left>
                {{ icons.mdiFilter }}
              </v-icon>
              Filitre
            </v-btn>

            <v-btn
              class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
              depressed
              x-large
              @click="addProduct()"
            >
              <span>Ürün Ekle</span>
            </v-btn>
          </v-toolbar>
          <v-expansion-panels v-else v-model="panel" flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="text-body-2 font-weight-medium">
                  <v-icon left>
                    {{ icons.mdiCollage }}
                  </v-icon>
                  Ürünler
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-divider></v-divider>
                <v-list class="pa-0" color="grey">
                  <v-list-item @click="addProduct()">
                    <v-list-item-content>
                      <v-list-item-title>Ürün Ekle</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click.stop="isFilterProductSidebarActive = !isFilterProductSidebarActive">
                    <v-list-item-content>
                      <v-list-item-title>Filitre</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon :color="isFilterNumber ? 'tertiary' : null">
                        {{ icons.mdiFilter }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="productListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalProductListTable"
            :loading="loading"
          >
            <!-- Text -->

            <template #[`item.dosya_adi`]="{ item }">
              <v-card
                tile
                class="rounded d-flex align-center justify-center"
                width="40"
                height="40"
                :color="item.pictures ? 'white' : 'primary'"
              >
                <v-img
                  v-if="item.pictures"
                  height="30"
                  width="30"
                  :src="'https://onlineapi.anlas.com/' + item.pictures.kresim[0].dosya_adi"
                ></v-img>

                <v-icon v-else>
                  {{ icons.mdiCameraOff }}
                </v-icon>
              </v-card>
            </template>

            <template #[`item.aktif`]="{ item }">
              <v-chip :color="item.aktif === '1' ? 'success' : 'warning'" link outlined small>
                {{ item.aktif === '1' ? 'Aktif' : 'Pasif' }}
              </v-chip>
            </template>

            <template #[`item.urun_kodu`]="{ item }">
              <div class="text-caption">
                {{ item.urun_kodu }}
              </div>
            </template>

            <template #[`item.plant`]="{ item }">
              <div class="d-flex">
                <v-chip
                  v-for="(plant, i) in plants(item.plant)"
                  :key="`${i}-plant`"
                  class="mr-1"
                  color="grey"
                  label
                  small
                >
                  <span class="text--secondary">{{ statusFind('fabrika', plant, 'text') }}</span>
                </v-chip>
              </div>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="nightDark"
                    x-small
                    class="rounded-r-0 rounded-l-xl"
                    :to="{ name: 'product-view', params: { data: item } }"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiEye }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ayrıntılar</span>
              </v-tooltip>

              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="nightDark"
                    :class="item.dosya_adi ? 'rounded-l-0 rounded-r-xl' : 'rounded-0'"
                    x-small
                    v-bind="attrs"
                    @click="editProduct(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiSquareEditOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Düzenle</span>
              </v-tooltip>

              <v-tooltip v-if="item.dosya_adi" bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="warning"
                    x-small
                    plain
                    class="rounded-l-0 rounded-r-xl"
                    v-bind="attrs"
                    @click="productImageDelete(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resimi Kaldır</span>
              </v-tooltip>
              <v-tooltip v-else bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="nightDark"
                    x-small
                    class="rounded-l-0 rounded-r-xl"
                    v-bind="attrs"
                    @click="productImageUpload(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      {{ icons.mdiCamera }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resim Yükle</span>
              </v-tooltip>

              <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed color="error" x-small plain v-bind="attrs" @click="productDelete(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ürünü Sil</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageUpload from '@/components/ImageUpload.vue'
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiCollage,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import productStoreModule from '../productStoreModule'
import productAddUpdate from './ProductAddUpdate.vue'
import productFilter from './ProductFilter.vue'

export default {
  components: {
    productAddUpdate,
    productFilter,
    ImageUpload,
    VSnackbars,
  },
  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'
    const snackbars = ref([])

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    const isAddUpdateProductSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterProductSidebarActive = ref(false)
    const isUploadProductImageActive = ref(false)
    const dataUpload = ref({})
    const productListTable = ref([])
    const productType = ref('add')
    const productItem = ref({})

    const tableColumns = [
      // {
      //   text: 'ID',
      //   value: 'id',
      //   sortable: false,
      //   width: '50px',
      // },
      {
        text: 'Sıra',
        value: 'sira',
        width: '90px',
      },
      {
        text: 'Resim',
        value: 'dosya_adi',
        width: '90px',
      },
      {
        text: 'Durum',
        value: 'aktif',
        width: '90px',
      },
      {
        text: 'Ürün Kodu',
        value: 'urun_kodu',
        width: '130px',
      },

      {
        text: 'Başlık',
        value: 'baslik',
      },

      {
        text: 'Fabrika',
        value: 'plant',
      },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalProductListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const productTotalLocal = ref([])
    const selectedRows = ref([])

    const productImageUpload = item => {
      dataUpload.value = item
      isUploadProductImageActive.value = true
    }
    const uploadImage = item => {
      dataUpload.value = item
      isUploadProductImageActive.value = true
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const productImageDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.pictures.bresim[0].uploadid,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Ürünün Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchProducts()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const productDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Ürünü silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deleteProduct',
              id: item.id,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Ürünü Silindi`,
                color: 'error',
                timeout: 5000,
              })

              fetchProducts()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const plants = val => {
      let result

      if (val) {
        result = val.split(',')
      } else {
        result = []
      }

      return result
    }
    const saveProductEvent = val => {
      snackbars.value.push(val)
    }
    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const editProduct = item => {
      productItem.value = item
      productType.value = 'edit'
      isAddUpdateProductSidebarActive.value = true
    }
    const addProduct = () => {
      productItem.value = {}
      productType.value = 'add'
      isAddUpdateProductSidebarActive.value = true
    }
    const filterData = item => {
      idFilter.value = item.id || null
      statusFilter.value = item.aktif || null
      urunKodFilter.value = item.urun_kodu || null
      baslikFilter.value = item.baslik || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    const fetchProducts = () => {
      const query = {
        options: options.value,
        id: idFilter.value,
        aktif: statusFilter.value,
        urun_kodu: urunKodFilter.value,
        baslik: baslikFilter.value,
        customer: customerFilter.value,
      }
      const method = 'adminProductList'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          productListTable.value = response.response.result
          totalProductListTable.value = Number(response.response.pagination.totalRec)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchProducts()
    })

    return {
      productImageDelete,
      statusFind,
      plants,
      productItem,
      editProduct,
      addProduct,
      productType,
      isFilterNumber,
      filterData,
      productListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalProductListTable,
      loading,
      options,
      productTotalLocal,
      isAddUpdateProductSidebarActive,
      isFilterProductSidebarActive,
      isUploadProductImageActive,
      selectedRows,
      fetchProducts,
      productDelete,
      snackbars,
      productImageUpload,
      dataUpload,
      uploadImage,
      saveProductEvent,
      saveImageEvent,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiCollage,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
