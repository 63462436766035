<template>
  <v-navigation-drawer
    :value="isAddUpdateProductSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="(val) => $emit('update:is-add-update-product-sidebar-active', val)"
  >
    <v-sheet
      :color="isDark ? '#363351' : '#fafafa'"
      class="pa-3 d-flex align-center justify-space-between"
    >
      <div class="d-flex align-center">
        <v-avatar
          size="48"

          color="secondary"
        >
          <v-icon
            size="24"
            class="white--text"
          >
            {{ formIcon }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          {{ formTitle }}
        </div>
      </div>
      <v-btn
        icon
        x-small

        @click="$emit('update:is-add-update-product-sidebar-active',false)"
      >
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>
    <v-dialog
      v-model="dialogKategori"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <v-avatar color="secondary">
            <v-icon size="24">
              {{ icons.mdiFileTree }}
            </v-icon>
          </v-avatar>
          <div class="ml-5">
            Ürün Kategorileri
          </div>
          <v-spacer />
          <v-btn

            icon
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-treeview
            v-model="productData.kategori"
            shaped
            selectable
            item-key="id"
            item-text="name"
            :items="categorys"
          >
          </v-treeview>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            color="secondary"
            @click="dialogKategoriSecim()"
          >
            Tamam
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <form>
      <validation-observer ref="observer">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Durum -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Durum"
              rules="required"
            >
              <v-select
                v-model="productData.aktif"
                label="Durum"
                :items="$store.state.tanimlar.durum"
                class="rounded-0"
                :dark="isDark"
                :menu-props="{ bottom: true, offsetY: true }"
                :error-messages="errors"
                filled
                hide-details
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Sıra -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Sıra"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.sira"
                filled
                :dark="isDark"
                label="Sıra"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <!-- Başlık -->
        <validation-provider
          v-slot="{ errors }"
          tag="div"
          mode="eager"
          name="Başlık"
          rules="required|max:500"
        >
          <v-text-field
            v-model="productData.baslik"
            filled
            :dark="isDark"
            label="Başlık"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Açıklama -->
        <validation-provider
          v-slot="{ errors }"
          tag="div"
          mode="eager"
          name="Açıklama"
          rules="required|max:500"
        >
          <v-textarea
            v-model="productData.kisa_aciklama"
            filled
            :dark="isDark"
            label="Açıklama"
            :error-messages="errors"
            hide-details
            auto-grow
            rows="2"
            class="rounded-0"
          ></v-textarea>
        </validation-provider>

        <!-- Ürün Kodu -->
        <validation-provider
          v-slot="{ errors }"
          tag="div"
          mode="eager"
          name="Ürün Kodu"
          rules="required|max:500"
        >
          <v-text-field
            v-model="productData.urun_kodu"
            filled
            :dark="isDark"
            label="Ürün Kodu"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Kategori -->
        <v-row
          no-gutters
        >
          <v-col cols="9">
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Ürün Kategorisi"
              rules="required"
            >
              <v-combobox
                v-model="kategoriSecim"
                filled
                multiple
                small-chips
                label="Ürün Kategorisi"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index <= 1"
                    small
                  >
                    <span>{{ item }}</span>
                  </v-chip>
                  <v-chip
                    v-else-if="index == 2"
                    x-small
                    outlined
                  >
                    + {{ kategoriSecim.length - 2 }}
                  </v-chip>
                </template>
              </v-combobox>
            </validation-provider>
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              style="height:56px !important"
              color="secondary"
              depressed
              block
              class="rounded-0"
              @click="dialogKategori=true"
            >
              Seç
            </v-btn>
          </v-col>
        </v-row>

        <!-- Fabrika  -->
        <validation-provider
          v-slot="{ errors }"
          tag="div"
          mode="eager"
          name="Fabrika"
          rules="required|max:500"
        >
          <v-select
            v-model="productData.plant"
            :items=" $store.state.tanimlar.fabrika"
            filled
            multiple
            :menu-props="{ bottom: true, offsetY: true }"
            :dark="isDark"
            label="Fabrika"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-select>
        </validation-provider>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Paket Adeti -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Paket Adeti"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.birim_ritmi"
                filled
                :dark="isDark"
                label="Paket Adeti"
                :error-messages="errors"
                persistent-placeholder
                placeholder="Kaçar Kaçar Alınabilir"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Birim -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Birim"
              rules="required"
            >
              <v-select
                v-model="productData.birim"
                filled
                :items=" $store.state.tanimlar.birim"
                :menu-props="{ bottom: true, offsetY: true }"
                :dark="isDark"
                label="Birim"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Minimum Satış Adeti -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Minimum Satış Adeti"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.min_satis_adeti"
                filled
                :dark="isDark"
                label="Minimum Satış Adeti"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Maksimum Satış Adeti -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Maksimum Satış Adeti"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.max_satis_adeti"
                filled
                :dark="isDark"
                label="Maksimum Satış Adeti"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Satış Fiyatı -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Satış Fiyatı"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.satis_fiyati"
                filled
                :dark="isDark"
                label="Satış Fiyatı"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Piyasa Fiyatı -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Piyasa Fiyatı"
              rules="required|max:500"
            >
              <v-text-field
                v-model="productData.piyasa_fiyati"
                filled
                :dark="isDark"
                label="Piyasa Fiyatı"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
          >
            <!-- Para Birimi -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="Para Birimi"
              rules="required|max:500"
            >
              <v-select
                v-model="productData.para_birimi"
                filled
                :dark="isDark"
                :items=" $store.state.tanimlar.para"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Para Birimi"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <!-- KDV Oranı (%) -->
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              mode="eager"
              name="KDV Oranı (%)"
              rules="required|max:500"
            >
              <v-select
                v-model="productData.kdv_orani"
                filled
                :dark="isDark"
                :items=" $store.state.tanimlar.kdv"
                :menu-props="{ bottom: true, offsetY: true }"
                label="KDV Oranı (%)"
                :error-messages="errors"
                hide-details
                class="rounded-0"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </form>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn
          x-large
          rounded
          block
          :loading="loading"
          color="secondary"
          @click="submit"
        >
          {{ formButtonText }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiEye, mdiEyeOff, mdiFileTree, mdiPencil, mdiPlusThick } from '@mdi/js'
import { convertDataTree } from '@utils'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  model: {
    prop: 'isAddUpdateProductSidebarActive',
    event: 'update:is-add-update-product-sidebar-active',
  },
  props: {
    isAddUpdateProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    productItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isDark } = useAppConfig()
    const category = ref('Tümü')
    const categorys = ref([])
    const open = ref(false)
    const search = ref(null)
    const hamCategorys = ref([])
    onMounted(() => {
      store
        .dispatch('postMethod', {
          method: 'getCategories',
        })
        .then(response => {
          hamCategorys.value = response.categoryList
          const categoryList = response.categoryList.map(item => {
            const items = {}
            let status = false
            if (item.aktif === 1) {
              status = true
            } else {
              status = false
            }
            items.id = Number(item.id)
            items.status = status
            items.parent_id = Number(item.ust)
            items.order = Number(item.sira)
            items.name = item.kategori.toLowerCase()
            items.slug = item.slug.replace('urunler/', '')

            return items
          })

          categorys.value = convertDataTree(categoryList)
          open.value = true
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    })

    return {
      isDark,
      open,
      search,
      category,
      categorys,
      hamCategorys,
      icons: {
        mdiClose,
        mdiEyeOff,
        mdiEye,
        mdiPlusThick,
        mdiPencil,
        mdiFileTree,
      },
    }
  },
  data: () => ({
    items: [],
    isLoading: false,
    search: null,
    show: false,
    loading: false,
    kategoriSecim: [],
    dialogKategori: false,
    productData: {
      aktif: '1',
      sira: 10,
      baslik: '',
      kisa_aciklama: '',
      urun_kodu: '',
      kategori: [],
      plant: [],
      birim_ritmi: 1,
      birim: 'AD',
      min_satis_adeti: 0,
      max_satis_adeti: 0,
      satis_fiyati: 0,
      piyasa_fiyati: 0,
      para_birimi: 'TL',
      kdv_orani: '18.00',
    },
    defaultItem: {
      aktif: '1',
      sira: 10,
      baslik: '',
      kisa_aciklama: '',
      urun_kodu: '',
      kategori: [],
      plant: [],
      birim_ritmi: 1,
      birim: 'AD',
      min_satis_adeti: 0,
      max_satis_adeti: 0,
      satis_fiyati: 0,
      piyasa_fiyati: 0,
      para_birimi: 'TL',
      kdv_orani: '18.00',
    },
  }),
  computed: {
    formTitle() {
      return this.productType === 'add' ? 'Yeni Ürün Ekle' : 'Ürünü Düzenle'
    },
    formIcon() {
      return this.productType === 'add' ? this.icons.mdiPlusThick : this.icons.mdiPencil
    },
    formButtonText() {
      return this.productType === 'add' ? 'Kaydet' : 'Güncelle'
    },
  },
  watch: {
    isAddUpdateProductSidebarActive(val) {
      if (val && this.productType === 'edit') {
        this.$nextTick(() => {
          const plant = this.productItem.plant.split(',')

          this.productData = {
            aktif: this.productItem?.aktif,
            sira: this.productItem?.sira,
            baslik: this.productItem?.baslik,
            kisa_aciklama: this.productItem?.kisa_aciklama,
            urun_kodu: this.productItem?.urun_kodu,
            plant,
            kategori: this.productItem?.birim_ritmi,
            birim_ritmi: this.productItem?.birim_ritmi,
            birim: this.productItem?.birim,
            min_satis_adeti: this.productItem?.min_satis_adeti,
            max_satis_adeti: this.productItem?.max_satis_adeti,
            satis_fiyati: this.productItem?.satis_fiyati,
            piyasa_fiyati: this.productItem?.piyasa_fiyati,
            para_birimi: this.productItem?.para_birimi,
            kdv_orani: this.productItem?.kdv_orani,
          }
        })
      }
      if (!val) {
        this.close()
      }
    },
  },

  methods: {
    dialogKategoriSecim() {
      this.kategoriSecim = []
      // eslint-disable-next-line array-callback-return
      this.hamCategorys.filter(item => {
        if (this.productData.kategori.includes(Number(item.id))) {
          this.kategoriSecim.push(item.kategori)
        }
      })
      this.dialogKategori = false
    },
    async submit() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.loading = false

        Vue.swal({
          title: 'Hata',
          text: 'Lütfen kırımızı alanları doldurun',
          icon: 'error',
          timer: 2000,
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })

        return
      }

      const data = this.productData

      const { id } = this.productItem

      const search = ['baslik', 'kisa_aciklama']
      const method = 'saveProduct'
      this.$store
        .dispatch('addUpdateMethod', {
          data,
          search,
          method,
          id,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.$emit('refetch-data')
            this.$emit('update:is-add-update-product-sidebar-active', false)

            if (!this.productItem.id) {
              this.$emit('upload-image', { id: response.response.insertId })
            }
            this.$emit('save-product-event', {
              message: `${this.productData.baslik} Ürünü Eklendi`,
              color: 'success',
              timeout: 5000,
            })
            this.loading = false
          }
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          this.$emit('update:is-add-update-product-sidebar-active', false)

          this.loading = false
        })
    },
    close() {
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.productData = { ...this.defaultItem }
      })
    },
  },
}
</script>
